var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('b-dropdown',{attrs:{"data-cy":"CollectionDropdownAction","no-caret":"","toggle-class":"collectionDropdown","variant":"light","id":("collection-" + _vm.collectionName)},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-v"})]},proxy:true}])},[_c('b-dropdown-group',{attrs:{"header":"Actions"}},[_c('b-dropdown-item',{attrs:{"disabled":!_vm.canEditCollection(_vm.indexName, _vm.collectionName),"title":!_vm.canEditCollection(_vm.indexName, _vm.collectionName)
            ? 'Your rights do not allow you to edit this collection'
            : '',"to":_vm.canEditCollection(_vm.indexName, _vm.collectionName)
            ? {
                name: 'EditCollection',
                params: {
                  collectionName: _vm.collectionName,
                  indexName: _vm.indexName
                }
              }
            : ''}},[_vm._v(" Edit collection ")]),(
          _vm.$store.direct.getters.kuzzle.currentEnvironment
            .backendMajorVersion !== 1
        )?_c('b-dropdown-item',{attrs:{"data-cy":"CollectionDropdown-delete"},on:{"click":_vm.onDeleteCollectionClicked}},[_vm._v(" Delete collection ")]):_vm._e(),_c('b-dropdown-item',{staticClass:"text-secondary",attrs:{"data-cy":"CollectionDropdown-clear","disabled":!_vm.canTruncateCollection(_vm.indexName, _vm.collectionName),"title":!_vm.canTruncateCollection(_vm.indexName, _vm.collectionName)
            ? 'Your rights do not allow you to truncate this collection'
            : ''},on:{"click":function($event){$event.preventDefault();return _vm.openModal($event)}}},[_vm._v(" Clear documents ")])],1)],1),_c('modal-clear',{attrs:{"id":'collection-clear-' + _vm.collectionName,"index":_vm.indexName,"collection":_vm.collectionName},on:{"clear":function($event){return _vm.$emit('clear')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }